import React, { useContext } from 'react';
import { ThemeContext } from "ToolboxUtils/web/context/context";
import { LoaderWrapper } from 'ToolboxComponents/commons/loader/loader.styles';

const Loader = props => {
  const theme = useContext(ThemeContext).state;
  return (
    <LoaderWrapper theme={theme}>
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </LoaderWrapper>
  );
};

export default Loader;
