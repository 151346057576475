import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { WhiteLabelContext, LanguageContext, GeolocationContext } from "ToolboxUtils/web/context/context";
import PublicProfileView from 'ToolboxComponents/webapp/pages/public-profile-view/public-profile-view';
import Loader from 'ToolboxComponents/commons/loader/loader';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';

import { PAGE_LOAD_WAITING } from 'Params/globals';

const PublicProfile = (props) => {
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const [{latitude, longitude}] = useContext(GeolocationContext).usePath('coordinates');

  const accountId = props.match.params.id;

  const [account, setAccount] = useState();
  const [secrets, setSecrets] = useState();
  const [pageInitTime, setPageInitTime] = useState(Date.now());
  const [isLoaderCountDownSet, setLoaderCountDown] = useState(false);
  const [isLoaderDisplayed, showLoader] = useState(false);

  if (!isLoaderCountDownSet) {
    setLoaderCountDown(true);
    setTimeout(function(){
      if (!(account !== undefined && secrets !== undefined)) {
        showLoader(true);
      }
    }, PAGE_LOAD_WAITING - (Date.now() - pageInitTime));
  }

  useEffect(() => {
    return () => {
      setLoaderCountDown(false);
      setPageInitTime(null);
    }
  }, []);

  const sendLogs = error => {
    apiChuchoteurs.post(`/logs`, {
      body: {error: {...error, type: 'front'}}
    })
  };

  useApiChuchoteurs.get(`/accounts/${accountId}/public-profile`, {
    params: { language, whiteLabelId: whiteLabel.id },
    onComplete: results => {
      if (results.isHttpError === false) {
        setAccount(results.data)
      } else if (results.isHttpError && results.error) {
        sendLogs(results.error);
      }
    },
  });

  useApiChuchoteurs.get(`/secrets`, {
    params: {
      accountId,
      longitude: longitude,
      latitude: latitude,
      filter: 'account-public',
      language: language,
      whiteLabelId: whiteLabel.id,
    },
    onComplete: results => setSecrets(results.data),
  });

  useIsContentLoaded(account !== undefined && secrets !== undefined);
  return (
    <>
      <Helmet>
        <title>{`${account ? account.firstName : "Profil public"} - ${whiteLabel.name}`}</title>
      </Helmet>
      {account !== undefined && secrets !== undefined 
        ? <PublicProfileView account={account} secrets={secrets} whiteLabelId={whiteLabel.id}/>
        : isLoaderDisplayed
          ? <Loader />
          : <></>
      }
    </>
  );
};

export default PublicProfile;
