export default function formatDistance(m) {
  const distance = parseFloat(m);

  if (distance >= 100000) { // 100km et plus => on arrondit au km
    return Math.round(distance / 1000) + ' km';
  } else if (distance >= 1000) { // de 1km à 100km => on arrondit au premier décimal après le km
    return (Math.round((distance / 1000) * 10) / 10) + ' km';
  } else if (distance >= 300) { // de 300m à 1km => on arrondit au multiple de 50 supérieur
    return (Math.ceil(distance / 50) * 50) + ' m';
  } else if (distance >= 100) { // de 100m à 300m => on arrondit au multiple de 10 supérieur
    return (Math.ceil(distance / 10) * 10) + ' m';
  } else {
    return Math.round(distance) + ' m';
  }
}
