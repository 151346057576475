import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DottedBackgroundWrapper = styled.div`
  background: ${({ color }) => color}0f;
  height: 100%;
  width: 100%;
`;

const DottedBackground = ({ color, ...rest }) => {
  return (
    <DottedBackgroundWrapper color={color} {...rest}>
      <svg width="100%" height="100%">
        <defs>
          <pattern
            id="polka-dots"
            x="0"
            y="0"
            width="15"
            height="15"
            patternUnits="userSpaceOnUse"
          >
            <circle fill={`${color}1f`} cx="10" cy="10" r="5" />
          </pattern>
        </defs>

        <rect x="0" y="0" width="100%" height="100%" fill="url(#polka-dots)" />
      </svg>
    </DottedBackgroundWrapper>
  );
};

DottedBackground.propTypes = {
  color: PropTypes.string.isRequired, // accepts only an hexadecimal color ex: #ff00ff
};

export default DottedBackground;
