import formatScheduleEvent from './format-schedule-event';
import formatScheduleProduct from './format-schedule-product';


export default function formatSchedule(isEvent, schedule, moment) {
  if (isEvent) {
    return formatScheduleEvent(schedule, moment);
  } else {
    return formatScheduleProduct(schedule, moment);
  }
}
