import PropTypes from 'prop-types';
import formatDistance from 'ToolboxUtils/web/formats/distance'

const DistanceConverted = props => formatDistance(props.distance);

DistanceConverted.propTypes = {
  distance: PropTypes.number.isRequired
}

export default DistanceConverted;
