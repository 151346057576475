import capitalizeFirstLetter from 'ToolboxUtils/general/capitalize-first-letter';

const formatShortDate = ({ date, language, options = {
  weekday: 'long',
  day: '2-digit',
  month: 'long',
}}) => {
  return capitalizeFirstLetter(date.toLocaleDateString(language, options));
};

export default formatShortDate;