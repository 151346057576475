import { useEffect, useContext } from "react";
import { WebappContext } from "ToolboxUtils/web/context/context";


const useIsContentLoaded = (depends, value) => {
  const [isContentLoaded, setIsContentLoaded] = useContext(WebappContext).usePath('isContentLoaded');

  useEffect(() => {
    setIsContentLoaded(value === undefined ? depends : value);
  }, [depends, value, setIsContentLoaded]);

  return isContentLoaded;
}

export default useIsContentLoaded;
