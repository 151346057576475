import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

export const PublicProfileViewWrapper = styled.div`
  .header-background {
    height: 120px;
    .dotted-background {
      position: fixed;
      height: 120px;
      z-index: -1;
      @media (min-width: 576px) {
        max-width: 540px;
      }

      @media (min-width: 768px) {
        max-width: 720px;
      }

      @media (min-width: 992px) {
        max-width: 960px;
      }

      @media (min-width: 1200px) {
        max-width: 1140px;
      }
    }
  }

  .title-section {
    display: flex;
    flex-flow: column;
    align-items: center;
    background: white;

    .title-section-image {
      margin-top: -85px;
      width: 170px;
      height: 170px;
      border-radius: 50%;
      overflow: hidden;
      border: 5px solid white;
      margin-bottom: 5px;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      .title-section-image-placeholder {
        height: 100%;
        background: ${props => props.theme.color.tertiaryColor};
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 228px;
        }
      }
    }

    .title-section-title {
      font-size: ${props => `${20*props.theme.font.secondaryFont.scaling/100}px`};
      font-family: ${props => props.theme.font.secondaryFont.name};
      text-transform: uppercase;
      font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
      margin-bottom: 20px;
    }
    .business {
      align-items: center;
      background: ${props => props.theme.color.primaryColor};
      border-radius: 30px;
      color: white;
      display: flex;
      font-family: ${props => props.theme.font.secondaryFont.name};
      margin-bottom: 20px;
      padding: 5px;
      padding-left: 10px;
      &:hover {
        text-decoration: none;
      }
      .icon {
        font-size: 40px;
      }
      .business-title {
        padding: 0 10px;
        font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
      }
      img {
        border-radius: 25px;
        height: 50px;
        width: 50px;
      }
    }
  }

  .description-section {
    display: flex;
    flex-flow: column;
    align-items: center;
    background: white;

    .box {
      flex: 1;
      display: flex;
      justify-content: center;
    }

    .description-section-text {
      width: 300px;
      margin-bottom: 40px;
    }

    .description-section-card {
      width: 240px;
      background: ${props => props.theme.color.tertiaryColor};
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 0 26px 10px;
      border-radius: 8px;
      margin-bottom: 60px;

      .icon {
        font-size: 92px;
        color: ${props => props.theme.color.primaryColor};
      }

      .description-section-card-text {
        text-align: center;
        margin-bottom: 10px;
        line-height: 20px;
        white-space: normal;

        span {
          font-size: ${props => `${24*props.theme.font.primaryFont.scaling/100}px`};
          color: ${props => props.theme.color.primaryColor};
          font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
        }
      }

      .description-section-card-thanks {
        font-family: ${props => props.theme.font.secondaryFont.name};
        color: ${props => props.theme.color.primaryColor};
        text-transform: uppercase;
      }
    }
  }

  .slider-section {
    background: ${props => props.theme.color.tertiaryColor};
    padding: 20px 0;

    .slider-section-title {
      font-size: ${props => `${20*props.theme.font.secondaryFont.scaling/100}px`};
      font-family: ${props => props.theme.font.secondaryFont.name};
      text-transform: uppercase;
      text-align: center;
    }

    .slider-section-thanks {
      color: ${props => props.theme.color.primaryColor};
      text-align: center;
      margin-bottom: 40px;
    }

    .swiper-slide {
      padding: 0 10px;
    }
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    .description-section {
      flex-flow: row;
      justify-content: center;

      .description-section-text {
        width: 640px;
        padding: 0 40px;
      }

      .badge {
        order: -1;
        display: flex;
        justify-content: center;
      }
    }

    .slider-section {
      margin-bottom: 40px;
    }

    .slider-section-slider {
      padding: 0 10px;
    }

    .swiper-slide {
      padding: 0;
    }
  }
`;
