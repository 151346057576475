import React, { useContext } from 'react';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import { PublicProfileViewWrapper } from 'ToolboxComponents/webapp/pages/public-profile-view/public-profile-view.styles';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import DottedBackground from 'ToolboxComponents/commons/patterns/dotted-background/dotted-background';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import PropTypes from "prop-types";
import SecretHomeCard from 'ToolboxComponents/commons/cards/secret-home-card/secret-home-card';
import SecretProductCard from 'ToolboxComponents/commons/cards/secret-product-card/secret-product-card';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import Text from 'ToolboxComponents/commons/texts/text/text';

const PublicProfileView = ({ account, secrets,whiteLabelId }) => {
  const theme = useContext(ThemeContext).state;
  return (
    <PublicProfileViewWrapper theme={theme}>
      <div className='header-background'>
        <DottedBackground color={theme.color.primaryColor} className={'dotted-background'} />
      </div>
      <div className='title-section'>
        <div className='title-section-image'>
          {account.photo !== null
            ? <ImageResize src={account.photo} />
            : <div className='title-section-image-placeholder'>
                <i className='adn adn-account' />
              </div>
          }
        </div>
        <div className='title-section-title'>{account.firstName}</div>
        {account.businessLink !== null && whiteLabelId !== 2 &&
          <a className='business' href={account.businessLink} target='_blank'>
            <span className={`adn adn-${account.businessIcon} icon`} />
            <span className='business-title'>{account.businessTitle}</span>
            <img src={account.businessLogo}/>
          </a>
        }
      </div>
      <div className='description-section'>
        {account.biography !== null && (
          <div className='box'>
            <div className='description-section-text'>
              {account.biography}
            </div>
          </div>
        )}
        {secrets.length >= 5 && (
          <div className='box badge'>
            <div className='description-section-card'>
              <i className='icon adn adn-star-circle' />
              <Text
                path='page.publicProfile.superWhisperer'
                data={{ secretsCount: secrets.length }}
                className='description-section-card-text'
              />
              <div className='description-section-card-thanks'>
                <Text path='page.publicProfile.superWhispererThanks' data={{ name: account.firstName }} />
              </div>
            </div>
          </div>
        )}
        {account.biography !== null && secrets.length >= 5 && (
          <div className='box' />
        )}
      </div>
      <div className='slider-section'>
        <div className='slider-section-title'>
          <Text
            path='page.publicProfile.sliderTitle'
            data={{
              name: account.firstName,
              secretsCount: secrets.length,
              secretPlural: secrets.length > 1 ? 's' : '',
              localPlural: secrets.length > 1 ? 'ux' : 'l',
            }}
          />
        </div>
        <div className='slider-section-thanks'>
          <Text path='page.publicProfile.sliderThanks' />
        </div>
        {secrets.length > 0 && (
          <div className='slider-section-slider'>
            <Slider
              mobile={{
                dots: true,
                spaceBetween: 10,
              }}
              desktop={{
                arrows: true,
                dots: true,
                slidesPerView: 3,
                centerInsufficientSlides: true,
              }}
            >
              {secrets.map((secret, index) => (
                <React.Fragment key={index}>
                  <SecretProductCard
                    secret={secret}
                    hideQuotes={true}
                    hideUser={true}
                    roundedCorners={false}
                    alwaysWhite={true}
                    linkToProduct={!!secret.productId}
                    isPublicProfile={true}
                  />
                  {!!secret.productId && (
                    <SecretHomeCard
                      secret={secret}
                      imageTop={true}
                      isPublicProfile={true}
                    />
                  )}
                </React.Fragment>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </PublicProfileViewWrapper>
  );
};

PublicProfileView.propTypes = {
  account: PropTypes.object.isRequired,
  secrets: PropTypes.array,
  whiteLabelId: PropTypes.number.isRequired
}

export default PublicProfileView;
